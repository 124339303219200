import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">





<path d="M5225 8849 c-182 -10 -477 -70 -563 -115 -15 -8 -32 -14 -39 -14 -7
0 -27 -6 -45 -14 -18 -8 -53 -22 -78 -32 -80 -31 -174 -77 -199 -96 -13 -10
-32 -18 -41 -18 -18 0 -35 -28 -24 -39 3 -3 378 -7 834 -9 671 -2 830 -5 839
-16 8 -9 10 -126 9 -382 l-3 -369 -31 -3 c-40 -4 -48 8 -67 93 -43 194 -66
245 -152 330 -90 88 -215 146 -390 179 -58 10 -154 15 -346 16 -237 1 -267 -1
-273 -15 -3 -9 -6 -256 -6 -549 0 -430 3 -536 13 -551 12 -15 23 -17 78 -12
140 14 216 26 253 42 51 21 150 84 155 98 2 6 15 22 28 35 30 30 73 97 73 114
0 7 7 27 16 43 20 37 44 124 52 189 8 54 31 78 58 61 15 -9 16 -55 15 -450 -1
-258 3 -446 8 -454 8 -12 14 -9 35 16 98 116 252 233 307 233 11 0 19 4 19 10
0 5 17 12 38 15 20 4 54 14 76 23 30 13 72 16 195 16 159 0 238 -14 338 -60
17 -8 34 -14 38 -14 3 0 34 -14 68 -31 109 -54 209 -66 255 -32 20 15 29 33
35 71 6 37 14 53 28 57 39 13 36 46 54 -585 2 -63 6 -129 10 -146 5 -24 2 -37
-17 -59 -21 -24 -27 -26 -46 -16 -13 7 -23 25 -27 49 -10 60 -75 222 -114 282
-41 64 -137 170 -200 222 -42 35 -128 77 -209 102 -65 20 -104 26 -178 26
-119 0 -188 -19 -268 -74 -53 -36 -66 -52 -100 -120 -52 -105 -55 -140 -22
-226 22 -56 42 -84 109 -152 79 -80 126 -118 146 -118 5 0 42 -19 83 -43 40
-24 154 -84 254 -134 249 -125 454 -259 552 -361 23 -24 42 -47 42 -52 0 -4 9
-18 21 -31 71 -81 120 -246 121 -411 1 -129 -10 -174 -83 -330 -7 -16 -9 -31
-6 -35 4 -3 7 1 7 10 0 9 5 17 10 17 14 0 78 69 96 102 8 15 22 33 31 40 10 7
34 33 53 58 19 25 38 47 42 48 4 2 8 7 8 11 0 4 29 52 64 105 64 97 179 319
209 406 9 25 22 59 28 75 32 77 89 297 89 339 0 18 7 55 15 82 11 36 15 106
16 270 1 238 -8 323 -62 574 -15 67 -96 315 -114 350 -8 14 -34 68 -58 120
-45 96 -158 276 -214 340 -16 19 -44 55 -61 80 -47 69 -190 223 -252 271 -30
24 -61 49 -68 56 -7 7 -17 13 -20 13 -4 0 -18 10 -32 23 -22 20 -145 106 -188
132 -9 6 -43 27 -76 47 -33 21 -75 43 -95 49 -20 6 -43 17 -51 24 -8 7 -26 16
-40 20 -14 4 -47 17 -75 30 -60 27 -147 56 -235 79 -36 10 -85 23 -110 31 -25
7 -79 16 -120 20 -41 3 -91 10 -110 15 -46 12 -265 17 -415 9z"/>
<path d="M3828 8233 c-53 -54 -115 -121 -138 -150 -22 -28 -44 -53 -48 -55 -7
-3 -182 -245 -182 -253 0 -2 -21 -41 -48 -87 -46 -81 -140 -288 -161 -358 -93
-304 -135 -618 -113 -858 21 -229 44 -358 78 -455 8 -20 14 -48 14 -62 0 -13
4 -26 9 -29 5 -3 12 -18 15 -33 10 -40 52 -145 97 -241 21 -46 39 -85 39 -87
0 -3 29 -52 65 -110 36 -57 65 -109 65 -115 0 -5 3 -10 8 -10 4 0 40 -42 80
-94 81 -105 311 -334 387 -386 28 -19 55 -39 60 -45 15 -17 108 -81 146 -101
19 -9 36 -20 39 -24 3 -3 19 -13 35 -22 17 -8 73 -37 126 -65 53 -28 132 -62
175 -77 44 -15 88 -31 99 -36 11 -5 56 -18 100 -30 44 -12 98 -28 120 -36 23
-8 59 -14 80 -14 22 0 78 -7 125 -16 155 -28 435 -21 650 16 121 20 177 32
225 45 28 8 61 17 75 21 14 4 53 20 87 35 33 16 67 29 74 29 6 0 40 13 75 30
34 16 74 32 88 36 63 16 13 23 -146 23 -95 -1 -184 2 -198 5 -136 29 -209 53
-354 114 -52 22 -180 21 -205 -2 -10 -9 -23 -16 -29 -16 -6 0 -24 -17 -39 -37
-41 -55 -50 -63 -70 -63 -44 1 -43 -7 -41 471 l3 454 29 0 c24 0 30 -6 45 -46
10 -25 21 -60 24 -76 3 -17 20 -60 37 -96 16 -35 30 -69 30 -76 0 -12 6 -23
78 -136 60 -94 199 -228 276 -267 34 -17 68 -36 76 -42 39 -32 253 -82 311
-73 158 25 187 35 253 84 69 51 135 138 157 208 16 49 13 145 -7 203 -21 65
-76 144 -125 182 -24 19 -50 40 -57 47 -27 26 -195 120 -397 223 -280 143
-379 207 -500 326 -75 74 -164 193 -165 222 -1 4 -9 25 -20 47 -11 22 -19 50
-20 62 0 12 -5 34 -10 49 -6 14 -12 88 -14 163 -5 151 -21 214 -78 305 -48 76
-95 118 -178 159 -115 56 -157 68 -265 74 -83 5 -102 3 -113 -10 -10 -12 -12
-109 -8 -463 3 -247 8 -485 11 -529 8 -100 36 -146 114 -187 47 -25 64 -28
145 -28 58 0 96 -4 104 -12 18 -18 15 -46 -7 -57 -26 -14 -1356 -14 -1382 0
-21 11 -25 44 -8 55 6 3 57 10 113 14 161 12 204 41 241 165 21 72 22 2188 1
2250 -24 68 -46 61 -158 -52z"/>
<path d="M6184 4011 c-40 -10 -112 -58 -136 -92 -28 -38 -40 -103 -28 -149 16
-58 51 -93 141 -140 84 -44 99 -60 79 -85 -22 -26 -83 -18 -137 18 l-50 33
-56 -51 c-31 -28 -57 -56 -57 -61 0 -21 62 -76 107 -96 181 -80 402 21 403
185 0 86 -25 115 -156 185 -49 26 -90 54 -92 63 -2 10 8 21 27 29 26 10 38 10
78 -5 89 -31 84 -32 137 36 55 71 55 71 -34 112 -49 22 -172 32 -226 18z"/>
<path d="M3195 3998 c-9 -26 -93 -613 -88 -620 2 -5 42 -8 87 -8 l83 0 18 110
18 109 125 3 126 3 7 28 c4 16 9 52 11 80 2 41 0 52 -12 53 -8 1 -63 2 -122 3
l-108 1 0 28 c0 49 7 51 155 54 l139 3 6 25 c4 14 10 52 14 83 l7 57 -231 0
c-175 0 -232 -3 -235 -12z"/>
<path d="M3745 3998 c-2 -7 -16 -92 -30 -188 -14 -96 -32 -218 -40 -270 -8
-52 -16 -111 -17 -130 l-3 -35 236 -3 237 -2 5 22 c3 13 9 51 13 86 l7 62
-147 0 c-135 0 -146 1 -146 18 0 19 20 162 45 322 22 142 28 130 -70 130 -60
0 -87 -4 -90 -12z"/>
<path d="M4306 4001 c-3 -5 -24 -131 -46 -281 -22 -149 -43 -289 -46 -311 l-7
-39 236 0 235 0 12 70 c7 39 9 77 6 85 -4 12 -31 15 -146 15 l-140 0 6 28 c4
15 9 30 11 35 2 4 64 7 137 7 l133 0 7 51 c4 29 10 65 13 80 l6 29 -137 0
-137 0 3 33 3 32 147 3 c167 3 154 -5 164 103 l7 69 -231 0 c-127 0 -233 -4
-236 -9z"/>
<path d="M4846 3963 c-4 -27 -10 -63 -13 -80 l-5 -33 126 0 c91 0 126 -3 126
-12 0 -6 -69 -81 -152 -165 -84 -84 -155 -161 -159 -171 -3 -9 -9 -43 -13 -75
l-6 -57 262 2 262 3 7 40 c3 22 9 57 12 78 l6 37 -130 0 c-105 0 -130 3 -127
14 2 7 68 81 148 165 80 83 148 162 151 174 3 12 9 46 13 75 l7 52 -255 0
-254 0 -6 -47z"/>
<path d="M5420 4001 c0 -6 34 -102 76 -213 l75 -203 -15 -100 c-8 -55 -12
-103 -7 -108 4 -4 43 -7 87 -5 l79 3 18 105 c20 115 7 92 235 426 34 50 62 94
62 98 0 12 -199 6 -210 -6 -6 -7 -35 -53 -64 -103 -30 -49 -58 -91 -63 -92 -6
-2 -20 31 -32 74 -13 43 -26 89 -30 103 -7 24 -11 25 -109 28 -65 2 -102 -1
-102 -7z"/>
<path d="M6597 4003 c-5 -9 -28 -150 -52 -318 -24 -172 0 -246 99 -298 33 -17
58 -21 131 -21 80 0 96 3 143 29 102 56 143 132 172 327 31 201 40 262 40 276
0 10 -21 12 -87 10 l-88 -3 -12 -70 c-6 -38 -19 -122 -28 -185 -10 -63 -24
-131 -32 -152 -28 -71 -131 -88 -160 -27 -10 21 -7 58 17 215 16 104 30 197
30 207 0 15 -11 17 -84 17 -47 0 -87 -3 -89 -7z"/>
<path d="M7247 4003 c-3 -5 -15 -73 -27 -153 -12 -80 -33 -216 -46 -303 -13
-88 -24 -163 -24 -168 0 -5 78 -9 180 -9 154 0 187 3 232 20 95 35 141 98 142
191 1 65 -14 99 -56 123 l-30 18 31 22 c66 45 87 140 46 203 -34 51 -83 63
-272 63 -94 0 -174 -3 -176 -7z m283 -164 c9 -16 8 -26 -5 -45 -14 -21 -23
-24 -80 -24 l-64 0 5 33 c10 57 10 57 73 57 51 0 62 -3 71 -21z m-16 -225 c21
-20 20 -37 -3 -65 -16 -19 -30 -24 -90 -27 -70 -4 -71 -4 -71 21 0 13 3 39 6
56 l7 31 68 0 c48 0 72 -5 83 -16z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
